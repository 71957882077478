import React from "react";
import { Dashboard } from "@ui/sustentus";

const App = () => {
  return (
    <Dashboard
      sections={[
        {
          title: `Hello!`,
          description: "Welcome to the Sustentus Admin Dashboard",
          data: [
            // { name: 'Unsatisfied (0-6)', stat: '8', icon: "frown", iconColor: 'red' },
            // { name: 'Content (7-8)', stat: '34', icon: 'meh', iconColor: 'orange' },
            // { name: 'Satisfied (9-10)', stat: '154', icon: "smile", iconColor: 'green' },
            // { name: 'Licences', stat: '5', icon: "documentCheck", iconColor: 'blue' },
          ],
        },
        {
          title: "Requests",
          description:
            "The number of service requests awaiting review and submission into the bid pool.",
          data: {
            cards: [
              {
                title: "Realtime Snapshot",
                description: "Total request breakdown in realtime",
                data: [
                  {
                    name: "Pending",
                    stat: "17",
                    previousStat: "out of 138 new leads",
                    change: "12%",
                    changeType: "increase",
                  },
                  {
                    name: "Qualifying",
                    stat: "31",
                    previousStat: "10 qualified out",
                    change: "2.02%",
                    changeType: "increase",
                  },
                  {
                    name: "Not Active",
                    stat: "8",
                    previousStat: "awaiting customer response",
                    change: "4.05%",
                    changeType: "decrease",
                  },
                  {
                    name: "Converted",
                    stat: "82",
                    previousStat: "",
                    change: "4.05%",
                    changeType: "decrease",
                  },
                ],
              },
              {
                title: "Bid Conversions",
                description: "Pending requests to bid conversions rate",
              },
            ],
          },
        },
        {
          title: "Bids",
          description:
            "The number of bid requests currently active and awaiting Expert bids to be accepted.",
          data: {
            cards: [
              {
                title: "Bid Status",
                description: "Monthly Distribution of Request Status",
                data: [],
              },
              { title: "August", description: "Bid Breakdown for August" },
            ],
          },
        },
        {
          title: "Latest Activity",
          description: "The latest activity across all requests.",
          action: {
            text: "View All",
            handler: () => alert("handled"),
          },
          data: {
            columns: [
              { header: "Customer", accessor: "customer" },
              { header: "Request", accessor: "request" },
              { header: "Status", accessor: "status" },
              { header: "Last Modified", accessor: "updatedAt" },
              { header: "Assigned Expert", accessor: "expert" },
              { header: "Action", accessor: "action" },
            ],
            rows: [
              {
                customer: "Acme Corp", // Custom JSX in the cell
                request: "CRM Integration",
                status: "Open",
                updatedAt: "12/07/2024", // JSX with styling
                expert: "-", // JSX with styling
              },
            ],
          },
        },
        {
          title: "Bids per Product in the last 6 months",
          description:
            "The number of projects relating to a particular product and the number of licenses associated with each delivered product.",
          data: {
            columns: [
              { header: "Product", accessor: "product" },
              { header: "Open Requests", accessor: "requests" },
              { header: "In Progress", accessor: "progress" },
              { header: "Completed Projects", accessor: "completed" },
              { header: "Licences Activated", accessor: "licences" },
              { header: "Action", accessor: "action" },
            ],
            rows: [
              {
                product: "Work Management", // Custom JSX in the cell
                requests: "23",
                progress: "4",
                completed: "12", // JSX with styling
                licences: "5", // JSX with styling
              },
            ],
          },
        },
      ]}
    />
  );
};

export default App;
