import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "virtual:generated-pages-react";
import { Sidebar, Loading } from "@ui/sustentus";
import { useAccountState } from "@state/sustentus";
import { configureApi } from "@api/sustentus";

const App = () => {
  const { state, signOut } = useAccountState();
  const routing = useRoutes(routes);

  if (!state.isAuth) {
    return <Loading isTrue={!state.isAuth} />;
  }

  configureApi({
    apiUrl: import.meta.env.VITE_API_URL,
    role: "vendor",
    token: state.token,
  });

  return (
    <Sidebar
      isOpen={false}
      menu={[
        {
          name: "Dashboard",
          href: "/",
          current: false,
        },
        {
          name: "Service Requests",
          href: "/requests",
          current: false,
        },
        {
          name: "Bid Pool",
          href: "/bids",
          current: false,
        },
        {
          name: "Activity",
          href: "/activity",
          current: false,
        },
        {
          name: "Products",
          href: "/products",
          current: false,
        },
        {
          name: "Teams",
          href: "/teams",
          current: false,
        },
        {
          name: "Categories",
          href: "/categories",
          current: false,
        },
        {
          name: "Skills",
          href: "/skills",
          current: false,
        },
        {
          name: "Statuses",
          href: "/statuses",
          current: false,
        },
        // {
        //   name: "Team",
        //   href: "/team",
        // current: false//
        // },
      ]}
      subMenu={{
        title: "Users",
        items: [
          {
            name: "Admins",
            href: "/users/admin",
            current: false,
            initial: "A",
          },
          {
            name: "Managers",
            href: "/users/manager",
            current: false,
            initial: "M",
          },
          {
            name: "Experts",
            href: "/users/expert",
            current: false,
            initial: "E",
          },
          {
            name: "Vendors",
            href: "/users/vendor",
            current: false,
            initial: "V",
          },
          {
            name: "Customers",
            href: "/users/customer",
            current: false,
            initial: "C",
          },
        ],
      }}
      user={{
        ...state.user,
        menu: [
          { name: "Your profile", href: "/profile" },
          { name: "Sign out", onClick: signOut },
        ],
      }}
      footer={{
        label: "Settings",
        href: "/settings",
      }}
      children={routing}
    />
  );
};

export default App;
